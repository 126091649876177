<template>
  <div>
<!--    <div class="mt-3">-->
<!--      <button class="filter-button" @click="filter">-->
<!--        <span class="filter-button__font">-->
<!--          {{ $t("FILTER") }}-->
<!--        </span>-->
<!--      </button>-->
<!--    </div>-->

    <div class="stat-card mt-3">
      <div class="stat-card__column">
        <div class="stat-card__title">
          <span class="stat-card__font stat-card__font--primary">All time</span>
        </div>

        <div class="stat-card__description">
          <span class="stat-card__font stat-card__font--secondary">
            REFERRAL EARNINGS
          </span>
        </div>
      </div>

      <div class="stat-card__column stat-card__column--align-right">
        <div class="stat-card__title">
          <span class="stat-card__font stat-card__font--primary">
            {{ totalGross }}
          </span>
        </div>

        <div class="stat-card__description">
          <span class="stat-card__font stat-card__font--secondary">
            TOTAL (GROSS)
          </span>
        </div>
      </div>

      <div class="stat-card__column stat-card__column--align-right">
        <div class="stat-card__title">
          <span class="stat-card__font stat-card__font--primary">
            {{ totalNet }}
          </span>
        </div>

        <div class="stat-card__description">
          <span class="stat-card__font stat-card__font--secondary">
            TOTAL (NET)
          </span>
        </div>
      </div>
    </div>

    <InfinityScroll
      v-if="statisticsGroup && statisticsGroup.length"
      :items="statisticsGroup"
    >
      <!--      :has-more="Boolean(earning.hasMoreCards)"-->
      <!--      @rich-end="loadMore"-->
      <template #default="{ item }">
        <StatisticsSpoiler
          :card="item"
          :spoiler-key="item.id"
        />
      </template>
    </InfinityScroll>
  </div>
</template>
<script>
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import { mapActions, mapGetters } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import StatisticsSpoiler from "@/features/containers/statements/components/StatisticsSpoiler.vue";
import { Statistics } from "@/services/Stattistics";
export default {
  name: "StatisticsContent",
  mixins: [waitRequest, validationError],
  components: {
    StatisticsSpoiler,
    InfinityScroll,
  },
  computed: {
    ...mapGetters({
      cards: "statements/cards",
      statistics: "statements/statistics",
    }),
    statisticsGroup() {
      return Statistics.groupGyDate(this.statistics.data || {});
    },
    totalGross() {
      return `$${this.statistics?.all_time?.stat_amount}`;
    },
    totalNet() {
      return `$${this.statistics?.all_time?.stat_net}`;
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchStatistics: "statements/fetchStatistics",
    }),
    initState() {
      this.initialLoadCards();
    },

    filter() {
      console.log("filter");
    },

    createQueryConfig(config) {
      return {
        page: this.earning.page || 1,
        limit: this.earning.per_page || 30,

        ...config,
      };
    },

    loadStatistics() {
      return this.waitRequest(() => {
        return this.fetchStatistics().catch(this.checkErrors);
      });
    },
    loadMore() {
      return this.waitRequest(() => {
        return this.fetchStatistics().catch(this.checkErrors);
      });
    },

    async initialLoadCards() {
      this.$showSpinner();

      this.loadStatistics()
        .catch(this.checkErrors)
        .finally(() => {
          this.$hideSpinner();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.stat-card {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid $app-gray-29;
  border-radius: 6px;
  height: 60px;
  background-color: black;
  padding: 14px 17px;

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--align-right {
      align-items: flex-end;
    }
  }

  &__title {
  }

  &__font {
    &--primary {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      color: $white;
    }

    &--secondary {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 10px;
      color: $app-gray-30;
    }

    &--amount {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: $white;
    }

    &--right {
      text-align: right;
    }
  }

  &__description {
  }

  &__amount {
  }
}

.filter-button {
  width: auto;
  border-radius: 100px;
  background: $app-gray-25;
  padding: 6px 12px;

  &__font {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    color: $app-gray-19;
  }
}
</style>
