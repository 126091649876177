<template>
  <div>
    <InfinityScroll
      v-if="payout.data && payout.data.length"
      :items="payout.data"
      :has-more="Boolean(payout.to)"
      @rich-end="loadMore"
    >
      <template #default="{ item: card }">
        <PayoutCard :card="card" />
      </template>
    </InfinityScroll>
  </div>
</template>
<script>
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import { mapActions, mapGetters } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import PayoutCard from "@/features/containers/statements/components/PayoutCard.vue";
export default {
  name: "PayoutContent",
  mixins: [waitRequest, validationError],
  components: {
    PayoutCard,
    InfinityScroll,
  },
  computed: {
    ...mapGetters({
      payout: "payouts/payout",
    }),
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchPayout: "payouts/fetchPayout",
    }),
    initState() {
      console.log('initState')
      this.initialLoadCards();
    },

    createQueryConfig(config) {
      return {
        page: this.payout?.current_page || 1,
        limit: this.payout?.per_page || 30,

        ...config,
      };
    },

    loadPayout(config) {
      const queryConfig = this.createQueryConfig(config);
      return this.waitRequest(() => {
        return this.fetchPayout(queryConfig).catch(this.checkErrors);
      });
    },
    loadMore() {
      const queryConfig = this.createQueryConfig({});
      return this.waitRequest(() => {
        return this.fetchPayout(queryConfig).catch(this.checkErrors);
      });
    },

    async initialLoadCards() {
      this.$showSpinner();

      this.loadPayout()
        .catch(this.checkErrors)
        .finally(() => {
          this.$hideSpinner();
        });
    },
  },
};
</script>
