<template>
  <div>
    <div class="mt-1">
      <div
        class="referral-spoiler mt-3"
        :class="{ 'referral-spoiler--open': openSpoiler }"
      >
        <div class="referral-card__header">
          <div class="referral-card__card">
            <div class="referral-card__image-wrapper">
              <img
                :src="avatarImage"
                :alt="`user avatar ${avatarImage}`"
                class="referral-card__image"
              />
            </div>

            <div class="referral-card__description">
              <div class="referral-card__info">
                <span class="referral-card__font referral-card__font--name">
                  {{ name }}
                </span>
              </div>
              <div class="referral-card__title">
                <span
                  class="referral-card__font referral-card__font--description"
                >
                  {{ username }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="referral-card__right">
          <div
            class="referral-card__description referral-card__description--right"
          >
            <div class="referral-card__info">
              <span class="referral-card__font referral-card__font--name">
                {{ commission }}
              </span>
            </div>
            <div class="referral-card__title">
              <span
                class="referral-card__font referral-card__font--description"
              >
                COMMISSION
              </span>
            </div>
          </div>

          <button
            type="button"
            v-b-toggle="spoilerId"
            class="referral-spoiler__button referral-spoiler__button--arrow"
            :class="{
              'referral-spoiler__button--arrow--open': openSpoiler,
            }"
          >
            <CIcon
              name="arrow-to-top"
              path="profile"
              class="referral-spoiler__icon referral-spoiler__icon--arrow-to-top"
            />
          </button>
        </div>
      </div>

      <b-collapse :id="spoilerId" v-model="spoilerModel[spoilerKey]">
        <div class="referral-spoiler-content">
          <div class="referral-spoiler-content__hr"></div>

          <div class="referral-spoiler-content__list">
            <div class="referral-spoiler-content__item">
              <div class="referral-spoiler-content__left">
                <span
                  class="referral-spoiler-content__font referral-spoiler-content__font--item"
                >
                  {{ $t("Spending") }}
                </span>
              </div>

              <div class="referral-spoiler-content__right">
                <span
                  class="referral-spoiler-content__font referral-spoiler-content__font--amount"
                >
                  {{ amountSpending }}
                </span>
              </div>
            </div>

            <div class="referral-spoiler-content__item">
              <div class="referral-spoiler-content__left">
                <span
                  class="referral-spoiler-content__font referral-spoiler-content__font--item"
                >
                  {{ $t("Earnings") }}
                </span>
              </div>

              <div class="referral-spoiler-content__right">
                <span
                  class="referral-spoiler-content__font referral-spoiler-content__font--amount"
                >
                  {{ amountEarnings }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "ReferralSpoiler",
  components: { CIcon },
  props: {
    spoilerKey: {
      type: [String, Number],
    },
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    spoilerId() {
      return `collapse-spoiler-${this.spoilerKey}`;
    },
    openSpoiler() {
      return this.spoilerModel[this.spoilerKey];
    },
    avatarImage() {
      return this.card.referral?.avatar || "/mock/PFP.webp";
    },

    username() {
      return this.card?.referral?.username || "";
    },

    commission() {
      return `$${this.card?.commission}` || "";
    },

    name() {
      return this.card?.referral?.name || "";
    },

    amountSpending() {
      return `$${this.card?.spending || 0}`;
    },

    amountEarnings() {
      return `$${this.card?.earnings || 0}`;
    },
  },
  data() {
    return {
      randomKey: Math.random(),
      spoilerModel: {
        [this.spoilerKey]: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.referral-spoiler {
  $parent: &;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid $app-gray-29;

  &__buttons {
    //margin-top: 12px;
  }

  &__button {
    &:not(:first-of-type) {
      margin-top: 11px;
    }

    &--arrow {
      margin-left: auto;
      margin-top: 0;

      &--open {
        #{$parent}__icon--arrow-to-top {
          transform: rotate(360deg);
          transition: $ease-out-cubic;
        }
      }
    }
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    color: $black;
    flex: 1;
    white-space: nowrap;
  }

  &__icon {
    color: $black;
    &--discount {
      width: 17px;
      height: 17px;
      min-width: 17px;
      min-height: 17px;
      margin-right: 6px;
    }

    &--arrow-to-top {
      transform: rotate(180deg);
      margin-left: auto;
      width: 15px;
      height: 15px;
    }
  }

  &--open {
    border-radius: 6px 6px 0 0;
    border-bottom: 0;
  }
}

.referral-spoiler-content {
  padding: 0 16px 16px 16px;
  border: 1px solid $app-gray-29;
  border-radius: 0 0 6px 6px;
  border-top: 0;

  &__hr {
    border-top: 1px solid #e8e8e8;
    height: 0px;
    margin-left: -7px;
    margin-right: -7px;
  }

  &__header {
    padding: 0 0 16px;
    display: flex;
    justify-content: space-between;
  }

  &__font {
    &--head {
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 16px;
      color: $app-gray-33;
    }
  }

  &__icon-wrapper {
    margin-left: 6px;
    position: relative;
    bottom: 1px;
  }

  &__icon {
    &--info {
      color: $app-gray-33;
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }
}

.referral-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  height: 164px;
  padding: 16px 15px;
  margin-top: 16px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__image-wrapper {
  }

  &__image {
    width: 33px;
    height: 33px;
    border-radius: 50%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin-left: em(11);

    &--right {
      align-items: flex-end;
      margin-right: em(17);
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__title {
  }

  &__badge {
    margin-top: em(6);
  }

  &__font {
    &--description {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 10px;
      color: $app-gray-18;
    }

    &--name {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      color: $app-black;
    }

    &--toggle {
      font-weight: 400;
      font-size: 12px;
      color: $app-gray-18;
    }
  }

  &__badge {
  }

  &__info {
    display: inline-flex;
    align-items: center;
  }

  &__icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  &__button {
    margin-top: em(16);
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: em(14);
  }

  &__toggle {
    margin-left: em(10);
    font-size: 50%; // change toggle default size
  }

  &__tooltip {
    padding: em(18) em(18) em(20);
  }

  &__button {
    color: $black;
    text-decoration: none;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: em(15);
    }

    &:active {
      transform: none;
      filter: none;
    }
  }
}

.referral-spoiler-content {
  &__hr {
    margin-bottom: 11px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 16px;
    position: relative;
    &::after {
      content: "";
      width: 4px;
      height: 4px;
      position: absolute;
      left: -9px;
      top: 50%;
      border-radius: 50%;
      background-color: $app-blue;
      transform: translate3d(0, -50%, 0);
    }
  }

  &__font {
    &--item {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 12px;
      color: $app-black;
    }

    &--amount {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 12px;
      text-align: right;
      color: $app-gray-18;
    }
  }
}
</style>
