<template>
  <div class="chart-container">
    <canvas :id="chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    chartId: {
      type: [Number, String],
      required: true,
    },
    eventCallback: {
      type: Function,
    },
    selectedLine: {
      type: Number,
    },
    dataSetsData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    selectedLine: {
      immediate: true,
      handler(val) {
        this.highlite(val);
      },
    },
  },
  computed: {
    chart() {
      return `lineChart-${this.chartId}`;
    },
  },
  data() {
    return {
      chartObject: null,
    };
  },
  mounted() {
    const datasets = [...this.dataSetsData?.dataset];

    let ctx = document.getElementById(this.chart).getContext("2d");
    this.chartObject = new Chart(ctx, {
      type: "line",
      data: {
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },

        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
              },
              distribution: "linear",
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0.1, // Smooths the lines
          },
        },
      },
    });
  },

  methods: {
    highlite(index = 0) {
      // Reset all lines to default width
      if (!this.chartObject?.data) return;
      this.chartObject.data.datasets.forEach((dataset) => {
        dataset.borderWidth = 2;
      });
      this.chartObject.data.datasets;

      // Highlight the specific line
      this.chartObject.data.datasets[index].borderWidth = 5;

      // Update the chart
      this.chartObject.update();
    },
  },
};
</script>

<style scoped>
/* Добавьте здесь свои стили */
</style>
