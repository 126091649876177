<template>
  <div class="payout-card">
    <div class="payout-card__info">
      <div class="payout-card__date-time">
        <span class="payout-card__font payout-card__font--base">
          {{ dateTime.date }}
        </span>

        <span class="payout-card__font payout-card__font--secondary">
          {{ dateTime.time }}
        </span>
      </div>

      <div class="payout-card__amount">
        <span class="payout-card__font payout-card__font--base">
          {{ invoiceId }}
        </span>

        <span class="payout-card__font payout-card__font--secondary">
          {{ $t("general.payoutCard.invoiceId") }}
        </span>
      </div>

      <div class="payout-card__amount">
        <span class="payout-card__font payout-card__font--base">
          {{ amount }}
        </span>

        <span class="payout-card__font payout-card__font--secondary">
          {{ $t("general.payoutCard.amount") }}
        </span>
      </div>
    </div>

    <div class="payout-card__footer">
      <UiBadge class="payout-card__badge" size="medium" v-if="description">
        <span class="payout-card__font payout-card__font--badge">
          {{ description.message }}

          <CLink v-if="description.user" :to="`/${description.user}`">
            {{ description.name }}
          </CLink>

          <CIcon
            v-if="description.pending"
            name="loader"
            path="statements"
            class="payout-card__icon payout-card__icon--loader"
          />
          <CIcon
            v-else
            name="check"
            path="statements"
            class="payout-card__icon payout-card__icon--check"
          />
        </span>
      </UiBadge>
    </div>
  </div>
</template>
<script>
import UiBadge from "@/features/ui/common/UiBadge.vue";
import { currencyFormat, formatDateTime } from "@/tools/helpers";
import { mapGetters } from "vuex";
import CLink from "@/features/ui/CLink.vue";
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "PayoutCard",
  components: { CIcon, CLink, UiBadge },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings",
    }),
    dateTime() {
      return this.formatDateTime(this.card?.created_at);
    },

    description() {
      const cardType = this.card?.status;
      const answerCode = this.settings?.payout_statuses?.[cardType];
      const answers = {
        STATUS_PENDING: {
          message: `Pending`,
          pending: true,
        },
        STATUS_COMPLETE: {
          message: "Complete ",
        },
      };

      return answers[answerCode] || answers.TYPE_SUBSCRIPTION_NEW;
    },

    amount() {
      return this.currencyFormat(this.card?.face_amount || 0);
    },
    invoiceId() {
      return `#${this.card?.id || ""}`;
    },
  },
  methods: {
    currencyFormat,
    formatDateTime,
  },
};
</script>

<style lang="scss" scoped>
.payout-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  height: 100px;
  padding: 16px 15px 18px;
  margin-top: 16px;

  &__image {
    display: inline-block;
    width: 29px;
    height: 17px;
  }

  &__icon {
    &--check {
      color: $black;
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      margin-left: 3px;
    }

    &--loader {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      margin-left: 3px;

      ::v-deep .dot {
        fill: black;
        animation: pulse 1s infinite;
      }

      @keyframes pulse {
        0%,
        100% {
          r: 3;
        }
        50% {
          r: 8;
        }
      }
    }
  }

  &__font {
    &--base {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: $black;
    }

    &--secondary {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 10px;
      text-align: right;
      color: $app-gray-18;
      text-transform: uppercase;
    }

    &--badge {
      font-size: 12px;
      color: $app-gray-19;
    }

    &--name {
      color: $app-blue;
    }

    &--verified {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 12px;
      color: $app-gray-19;
    }
  }

  &__badge {
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__date-time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__footer {
    margin-top: em(6);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__badge {
    height: em(26);
  }
}
</style>
