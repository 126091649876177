<template>
  <div
    class="ui-badge"
    :class="{
      [`ui-badge--${currentSize}`]: currentSize,
      [`ui-badge--theme--${currentTheme}`]: currentTheme,
    }"
  >
    <span class="ui-badge__font">
      <slot />
    </span>
  </div>
</template>

<script>
const size = {
  small: "small",
  medium: "medium",
};

const theme = {
  default: "default",
  primary: "primary",
  danger: 'danger',
  third: 'third'
};
export default {
  name: "UiBadge",
  props: {
    size: {
      type: String,
      default: "small",
      validator: (variant) => Object.keys(size).includes(variant),
    },
    theme: {
      type: String,
      default: "default",
      validator: (variant) => Object.keys(theme).includes(variant),
    },
  },
  computed: {
    currentTheme() {
      return this.theme;
    },
    currentSize() {
      return this.size;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-badge {
  $parrent: &;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: auto;
  height: 19px;
  background: $app-gray-25;
  padding: 4px 9px;

  &--medium {
    height: 26px;

    #{$parrent}__font {
      font-size: 12px;
      color: $app-gray-19;
    }
  }

  &__font {
    font-weight: 400;
    font-size: 9px;
    color: $app-gray-19;
  }

  &--theme {
    &--default {
    }

    &--primary {
      background: $app-blue;

      #{$parrent}__font {
        color: white;
      }
    }

    &--third {
      background: #55a1ff;

      #{$parrent}__font {
        color: white;
      }
    }

    &--danger {
      background: #ff6e6e;

      #{$parrent}__font {
        color: white;
      }
    }

  }
}
</style>
<script setup></script>
