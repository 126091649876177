<template>
  <b-row class="statements-page">
    <b-col>
      <PageHeader :title="$t('general.statements.title')">
        <div class="statements-page__header">
          <button
            type="button"
            @click="() => {}"
            class="collections-header-button__button"
          >
            <CIcon name="question" class="collections-header-button__icon" />
          </button>
        </div>
      </PageHeader>

      <div class="info mt-3">
        <div class="info__icon-wrapper">
          <CIcon name="star" path="statements" class="info__icon" />
        </div>

        <div class="info__text">
          <i18n tag="span" class="info__font" path="general.statements.info">
            <template v-slot:underlineText>
              <span class="info__font info__font--anderline">
                {{ rating }}%
              </span>
            </template>
          </i18n>
        </div>
      </div>

      <div class="mt-1">
        <div
          class="statements-spoiler mt-3"
          :class="{ 'statements-spoiler--open': openSpoiler }"
        >
          <span class="statements-spoiler__font">
            {{ $t("general.statements.spoiler.primaryBalance") }}
          </span>

          <button
            type="button"
            v-b-toggle:collapse-spoiler
            class="statements-spoiler__button statements-spoiler__button--arrow"
            :class="{
              'statements-spoiler__button--arrow--open': openSpoiler,
            }"
          >
            <span
              class="statements-spoiler__font statements-spoiler__font--amount"
            >
              {{ primaryBalance }}
            </span>

            <CIcon
              name="arrow-to-top"
              path="profile"
              class="statements-spoiler__icon statements-spoiler__icon--arrow-to-top"
            />
          </button>
        </div>

        <b-collapse id="collapse-spoiler" v-model="openSpoiler">
          <div class="statements-spoiler__buttons">
            <div class="spoiler-content">
              <div class="spoiler-content__header">
                <div class="d-flex">
                  <span
                    class="spoiler-content__font spoiler-content__font--head"
                  >
                    {{ $t("general.statements.spoiler.pendingBalance") }}
                  </span>
                  <span class="spoiler-content__icon-wrapper">
                    <CIcon
                      name="info-circle"
                      path="statements"
                      class="spoiler-content__icon spoiler-content__icon--info"
                    />
                  </span>
                </div>
                <div>
                  <span
                    class="spoiler-content__font spoiler-content__font--head"
                  >
                    {{ pendingBalance }}
                  </span>
                </div>
              </div>

              <div class="spoiler-form__hr"></div>

              <div class="spoiler-form__form">
                <div class="spoiler-form__field-name">
                  <span
                    class="spoiler-form__font spoiler-form__font--field-name"
                  >
                    {{ $t("general.statements.spoiler.requestPayout") }}
                  </span>
                </div>

                <div class="spoiler-form__input">
                  <CInput
                    type="text"
                    rules="min_value:10"
                    name="amount"
                    theme="settings"
                    class="spoiler-form__field"
                    v-mask="currencyMask"
                    :custom-field-name="$t('general.statements.spoiler.amount')"
                    v-model="amount"
                    :label="$t('general.statements.spoiler.amount')"
                  >
                    <template #appendAddition>
                      <span class="spoiler-form__counter">
                        <span
                          class="spoiler-form__font spoiler-form__font--addition"
                        >
                          {{
                            $t("general.statements.spoiler.addition", {
                              amount: minPayout,
                            })
                          }}
                        </span>
                      </span>
                      <span
                        v-if="errors && errors?.has('amount')"
                        class="spoiler-input__error"
                      >
                        <span
                          class="spoiler-input__font spoiler-input__font--error"
                        >
                          {{ errors?.first("amount") }}
                        </span>
                      </span>
                    </template>
                  </CInput>
                </div>
              </div>

              <div class="spoiler-form__button-wrapper">
                <CButton
                  align="center"
                  @click.prevent="createPayout"
                  class="base-button spoiler-form__button"
                >
                  <span class="base-button__font">
                    {{ $t("general.statements.spoiler.buttonRequest") }}
                  </span>
                </CButton>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

      <div class="statements-page__tabs">
        <CRadio
          v-model="selectedTab"
          v-for="(item, index) in tabs"
          :key="`${index + '-tab'}`"
          :name="item.name"
          :value="item"
          group-name="statements-tab"
          class="statements-tab"
        >
          <template #default="{ isActive, value: tab }">
            <div
              class="statements-tab__item"
              :class="{ 'statements-tab__item--active': isActive }"
            >
              <CIcon
                :name="tab.icon"
                path="statements"
                class="statements-tab__icon"
              />
              <span class="statements-tab__font"> {{ tab.content }} </span>
            </div>
          </template>
        </CRadio>
      </div>

      <KeepAlive>
        <transition name="fade" mode="out-in">
          <component :is="selectedTab.renderComponentName" />
        </transition>
      </KeepAlive>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import CIcon from "@/features/ui/CIcon.vue";
import PageHeader from "@/layout/PageHeader.vue";

import { mapActions, mapGetters } from "vuex";
import CRadio from "@/features/ui/CRadio.vue";
import CInput from "@/features/ui/СInput.vue";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import CButton from "@/features/ui/СButton.vue";
// import vSelect from "vue-select";
import PayoutCard from "@/features/containers/statements/components/PayoutCard.vue";
import ReferralSpoiler from "@/features/containers/statements/components/ReferralsSpoiler.vue";
import EarningContent from "@/features/containers/statements/EarningContent.vue";
import PayoutContent from "@/features/containers/statements/PayoutContent.vue";
import ReferralContent from "@/features/containers/statements/ReferralContent.vue";
import StatisticsContent from "@/features/containers/statements/StatisticsContent.vue";
import User from "@/components/models/User";

const renderedComponents = {
  earnings: "EarningContent",
  payouts: "PayoutContent",
  statistics: "StatisticsContent",
  referrals: "ReferralContent",
};

export default {
  name: "PageStatements",
  components: {
    EarningContent,
    PayoutContent,
    ReferralSpoiler,
    ReferralContent,
    StatisticsContent,
    PayoutCard,
    // vSelect,
    CButton,
    CInput,
    CRadio,
    PageHeader,
    CIcon,
  },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      user: "user",
      statements: "statements/statements",
    }),
    currencyMask() {
      return createNumberMask({
        prefix: "$",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      });
    },
    isCreatorRole() {
      return this.user.role.includes(User.ROLE_CREATOR);
    },
    primaryBalance() {
      return `$${this.statements?.balance?.primary_balance || 0}`;
    },
    pendingBalance() {
      return `$${this.statements?.balance?.pending_balance || 0}`;
    },
    minPayout() {
      return this.statements?.settings?.min_payout || 0;
    },
    rating() {
      return this.statements?.creator_rating || 0;
    },
    isEarning() {
      return this.selectedTab.id === 1;
    },
    isPayout() {
      return this.selectedTab.id === 2;
    },
    isReferral() {
      return this.selectedTab.id === 4;
    },
    selectedTab: {
      get() {
        return this.tab;
      },

      set(tab) {
        this.tab = tab;
      },
    },
    tabs() {
      return [
        {
          id: 1,
          icon: "money",
          // postListType: postType.flows,
          renderComponentName: renderedComponents.earnings,
          content: this.$t("general.statements.tabs.earnings"),
        },
        {
          id: 2,
          icon: "bank",
          // postListType: postType.posts,
          renderComponentName: renderedComponents.payouts,
          content: this.$t("general.statements.tabs.payouts"),
        },
        {
          id: 3,
          icon: "stats",
          // postListType: MEDIA_TYPE,
          renderComponentName: renderedComponents.statistics,
          content: this.$t("general.statements.tabs.statistics"),
        },
        {
          id: 4,
          icon: "referral",
          // postListType: MEDIA_TYPE,
          renderComponentName: renderedComponents.referrals,
          content: this.$t("general.statements.tabs.referrals"),
        },
      ];
    },
  },
  data() {
    return {
      earningDate: { label: "Monthly Payouts" },
      earningOptions: [{ label: "Monthly Payouts" }],
      amount: 20,
      openSpoiler: false,
      tab: {
        id: 1,
        icon: "money",
        // postListType: postType.flows,
        renderComponentName: "EarningContent",
        content: this.$t("general.statements.tabs.earnings"),
      },
    };
  },
  created() {
    if(!this.isCreatorRole) {
      this.$router.push('/404')
    }
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchStatements: "statements/fetchStatements",
      addPayout: "payouts/addPayout",
    }),
    initState() {
      this.fetchStatements().catch(this.checkErrors);
    },
    async createPayout() {
      if (this.requestInProgress) return;
      this.$validator.errors.clear();

      const amount = this.amount.replace("$", "").replaceAll(",", "");
      return this.waitRequest(() => {
        return this.addPayout({ amount })
          .catch(this.checkErrors)
          .finally(() => {
            this.tab = this.tabs[1];
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.statements-page {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
  }

  &__tabs {
    display: flex;
    flex-wrap: nowrap;
  }

  &__title {
    margin-top: 18px;
  }
  &__description {
    margin-top: 8px;
  }

  &__font {
    &--title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 18px;
      color: $app-gray-26;
    }

    &--description {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 129%;
      color: $app-gray-27;
    }
  }

  &__controls {
    margin-top: 8px;
  }

  &__hr {
    margin-left: em(-15);
    margin-right: em(-15);
    border-bottom: 1px solid $app-gray-2;
    margin-top: 14px;

    &--1 {
      margin-top: 14px;
    }
  }
}

.collections-header-button {
  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.statements-tab {
  $parrent: &;
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-18;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-black;

      #{$parrent}__icon,
      #{$parrent}__font {
        color: $app-black;
      }
    }
  }
  &__icon-wrapper {
    margin-right: 3px;
  }
  &__icon {
    color: $app-gray-18;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }

  &__font {
    color: $app-gray-18;
    text-align: center;
    font-family: $font-default;
    font-style: normal;
    line-height: normal;
    font-weight: 600;
    font-size: 10px;

    &--active {
      color: $app-black;
      padding-bottom: em(16);
      border-bottom: 2px solid $app-black;
    }
  }
}

.statements-spoiler {
  $parent: &;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 6px 6px 6px 6px;
  background-color: $app-blue;

  &__buttons {
    //margin-top: 12px;
  }

  &__button {
    &:not(:first-of-type) {
      margin-top: 11px;
    }

    &--arrow {
      width: auto;
      margin-left: auto;
      margin-top: 0;

      &--open {
        #{$parent}__icon--arrow-to-top {
          transform: rotate(360deg);
          transition: $ease-out-cubic;
        }
      }
    }
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    color: $white;
    flex: 1;
    white-space: nowrap;

    &--amount {
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 16px;
      text-align: right;
      color: $white;
      margin-right: 13px;
    }
  }

  &__icon {
    color: $white;
    &--discount {
      width: 17px;
      height: 17px;
      min-width: 17px;
      min-height: 17px;
      margin-right: 6px;
    }

    &--arrow-to-top {
      transform: rotate(180deg);
      margin-left: auto;
      width: 15px;
      height: 15px;
    }
  }

  &--open {
    border-radius: 6px 6px 0 0;
  }
}

.spoiler-form {
  &__form {
    //margin-top: em(32);
    width: 100%;
    //margin-right: em(16);
    //margin-left: em(16);
  }

  &__hr {
    border-top: 1px solid #e8e8e8;
    height: 0px;
    margin-left: -7px;
    margin-right: -7px;
  }

  &__button {
    margin-top: em(12);
    //margin-right: em(15);
    margin-left: auto;
  }

  &__menu-button {
    width: 100%;
  }

  &__button-wrapper {
    display: flex;
  }

  &__input {
    margin-top: em(16);
    position: relative;
  }

  &__counter {
    position: absolute;
    top: 100%;
    left: 0;
  }

  &__field {
  }

  &__group-title {
    padding: em(21) 0 em(21) em(20);
  }

  &__field-name {
    margin-top: 12px;
  }

  &__font {
    &--field-name {
      font-size: em(14);
      font-weight: 500;
    }

    &--addition {
      font-family: $font-default;
      font-size: em(12);
      color: $app-font-color-placeholder;
      text-align: left;
    }

    &--group-title {
      font-family: $font-default;
      font-style: normal;
      line-height: normal;
      color: $black;
      font-size: em(18);
      font-weight: 500;
    }
  }

  &__icon {
    color: $app-gray-18;

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }
}

.spoiler-content {
  padding: 16px;
  border: 1px solid $app-gray-29;
  border-radius: 0 0 6px 6px;

  &__header {
    padding: 0 0 16px;
    display: flex;
    justify-content: space-between;
  }

  &__font {
    &--head {
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 16px;
      color: $app-gray-33;
    }
  }

  &__icon-wrapper {
    margin-left: 6px;
    position: relative;
    bottom: 1px;
  }

  &__icon {
    &--info {
      color: $app-gray-33;
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }
}

.info {
  display: flex;
  border: 1px solid $app-gray-29;
  border-radius: 6px;
  background-color: $black;

  &__icon-wrapper {
    padding: 7px 14px 9px;
  }

  &__text {
    padding: 11px 0 11px 0;
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    color: $white;

    &--anderline {
      text-decoration: underline;
      text-decoration-skip-ink: none;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
  }
}

.spoiler-input {
  &__error {
    position: absolute;
    top: 15px;
    display: inline-block;
    width: 100%;
    font-weight: normal;
    font-size: em(12);
    font-style: normal;
    line-height: 1.2;
    color: $red;
  }
}
</style>
