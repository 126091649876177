<template>
  <div>
    <InfinityScroll
      v-if="earning.data && earning.data.length"
      :items="earning.data"
      :has-more="Boolean(earning.next_page_url)"
      @rich-end="loadMore"
    >
      <template #default="{ item: card }">
        <EarningCard :card="card" />
      </template>
    </InfinityScroll>
  </div>
</template>
<script>
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import EarningCard from "@/features/containers/statements/components/EarningCard.vue";
import { mapActions, mapGetters } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
export default {
  name: "EarningContent",
  mixins: [waitRequest, validationError],
  components: {
    EarningCard,
    InfinityScroll,
  },
  computed: {
    ...mapGetters({
      cards: "statements/cards",
    }),
    earning() {
      return this.cards.earning || [];
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchEarnings: "statements/fetchEarnings",
    }),
    initState() {
      this.initialLoadCards();
    },

    createQueryConfig(config) {
      return {
        page: this.earning.current_page || 1,
        limit: this.earning.per_page || 30,

        ...config,
      };
    },

    loadEarning(config) {
      const queryConfig = this.createQueryConfig(config);
      return this.waitRequest(() => {
        return this.fetchEarnings(queryConfig).catch(this.checkErrors);
      });
    },
    loadMore() {
      const queryConfig = this.createQueryConfig({ page:  this.earning.current_page + 1 });
      return this.waitRequest(() => {
        return this.fetchEarnings(queryConfig).catch(this.checkErrors);
      });
    },

    async initialLoadCards() {
      this.$showSpinner();

      this.loadEarning()
        .catch(this.checkErrors)
        .finally(() => {
          this.$hideSpinner();
        });
    },
  },
};
</script>
