<template>
  <div>
    <div class="referral-link-card mt-3">
      <div class="referral-link-card__column">
        <div class="referral-link-card__row">
          <div class="referral-link-card__title">
            <span
              class="referral-link-card__font referral-link-card__font--primary"
            >
              {{ $t("general.referralSpoiler.referralLink") }}
            </span>
          </div>

          <button
            class="referral-link-card__button"
            @click="$popup.open('WhatReferralPopup')"
          >
            <span
              class="referral-link-card__font referral-link-card__font--button"
            >
              {{ $t("general.referralSpoiler.howItWorks") }}
            </span>
          </button>
        </div>
      </div>

      <button class="referral-link-card__link" role="button" @click="copy">
        <span class="referral-link-card__font referral-link-card__font--link">
          {{ link }}
        </span>
      </button>
    </div>

    <div class="stat-card mt-3">
      <div class="stat-card__column">
        <div class="stat-card__title">
          <span class="stat-card__font stat-card__font--primary">
            {{ $t("general.referralSpoiler.allTime") }}
          </span>
        </div>

        <div class="stat-card__description">
          <span class="stat-card__font stat-card__font--secondary">
            {{ $t("general.referralSpoiler.refEarnings") }}
          </span>
        </div>
      </div>

      <div class="stat-card__column">
        <div class="stat-card__amount">
          <span class="stat-card__font stat-card__font--amount">
            {{ amount }}
          </span>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <span>
        {{ $t("general.referralSpoiler.yourReferrals") }}
      </span>
    </div>

    <InfinityScroll
      v-if="referralsList.data && referralsList.data.length"
      :items="referralsList.data"
      :has-more="Boolean(referralsList.next_page_url)"
      @rich-end="loadMore"
    >
      <template #default="{ item: card }">
        <ReferralSpoiler
          :card="card"
          :spoiler-key="card.referral && card.referral.id"
        />
      </template>
    </InfinityScroll>
  </div>
</template>
<script>
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import ReferralSpoiler from "@/features/containers/statements/components/ReferralsSpoiler.vue";
import { mapActions, mapGetters } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

export default {
  name: "ReferralContent",
  components: {
    ReferralSpoiler,
    InfinityScroll,
  },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      referrals: "statements/referrals",
    }),
    referralsList() {
      return this.referrals?.data || [];
    },
    link() {
      return this.referrals?.info?.link || "";
    },
    amount() {
      return `${this.referrals?.info?.all_time}` || "$0";
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchReferrals: "statements/fetchReferrals",
    }),
    initState() {
      if (!this.referralsList?.data?.length) {
        this.waitRequest(() => {
          return this.fetchReferrals().catch(this.checkErrors);
        });
      }
    },

    copy() {
      navigator.clipboard.writeText(this.link);

      this.$bvToast.toast(this.$t("general.copy"), {
        autoHideDelay: 2000,
        title: this.$t("general.success"),
        solid: true,
        toaster: "b-toaster-bottom-left flow-toast",
      });
    },

    createQueryConfig(config) {
      return {
        page: this.referralsList.current_page || 1,
        limit: this.referralsList.per_page || 30,

        ...config,
      };
    },

    loadMore() {
      if (this.requestInProgress && !this.referralsList.next_page_url) return;
      const queryConfig = this.createQueryConfig({
        page: this.referralsList.current_page + 1,
      });

      return this.waitRequest(() => {
        return this.fetchReferrals(queryConfig).catch(this.checkErrors);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.referral-link-card {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 18px 17px 20px;

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    flex: 1;
  }

  &__font {
    &--primary {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      color: $app-black;
      //text-wrap: nowrap;
    }

    &--button {
      font-family: var(--second-family);
      font-weight: 700;
      font-size: 7px;
      text-align: center;
      color: $white;
    }

    &--link {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      color: $app-gray-19;
    }
  }

  &__button {
    width: auto;
    padding: 7px;
    border-radius: 100px;
    background: $app-blue;
    align-self: flex-end;
  }

  &__link {
    padding: 8px 13px;
    margin-top: 11px;
    border-radius: 100px;
    background: $app-gray-25;
    justify-content: flex-start;
  }
}

.stat-card {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid $app-gray-29;
  border-radius: 6px;
  height: 60px;
  background-color: black;
  padding: 14px 17px;

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
  }

  &__font {
    &--primary {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      color: $white;
    }

    &--secondary {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 10px;
      color: $app-gray-30;
    }

    &--amount {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: $white;
    }
  }

  &__description {
  }

  &__amount {
  }
}
</style>
