import Decimal from "decimal.js-light";
Decimal.set({ precision: 64, toExpNeg: -16 });

export class Statistics {
  static statisticTypes = {
    0: "subscriptions",
    1: "subscriptions",
    10: "posts",
    11: "messages",
    20: "tips",
    30: "TYPE_WALLET_DEPOSIT",
    40: "TYPE_REFERRAL_COMMISSION",
  };

  static colors = {
    subscriptions: "blue",
    tips: "turquoise",
    posts: "crimson",
    messages: "orange",
    // referrals: "violet",
  };

  static groupGyDate(items) {
    const groupedData = {};
    Object.values(items)
      .toSorted((a, b) => new Date(a.created_at) - new Date(b.created_at))
      .toReversed()
      .forEach((item) => {
        const formattedDate = Statistics.formatDate(new Date(item.created_at));
        if (!groupedData[formattedDate]) {
          groupedData[formattedDate] = [];
        }
        groupedData[formattedDate].push(item);
      });

    return Object.entries(groupedData).map(([key, value]) => ({
      title: key,
      id: Math.random() + key.replaceAll(" ", ""),
      data: value,
    }));
  }

  static aggregate(data) {
    const group = {
      subscriptions: "0.00",
      tips: "0.00",
      posts: "0.00",
      messages: "0.00",
      referrals: "0.00",
      net: "0.00",
      gross: "0.00",
    };

    data.forEach((item) => {
      const groupName = Statistics.statisticTypes[item.type];
      group[groupName] = new Decimal(group[groupName] || "0")
        .add(new Decimal(item.face_amount || 0))
        .toFixed(2);
      group.net = new Decimal(group.net || 0)
        .add(new Decimal(item.face_net))
        .toFixed(2);
      group.gross = new Decimal(group.gross || 0)
        .add(new Decimal(item.face_amount))
        .toFixed(2);
    });

    return group;
  }

  static prepareChartData(data) {
    const mockGroups = {
      subscriptions: [],
      posts: [],
      messages: [],
      tips: [],
      // referrals: [],
    };

    const transformedData = Object.values(data);
    const groupByTypes = Object.groupBy(
      transformedData,
      ({ type }) => Statistics.statisticTypes[type]
    );

    const dataset = Object.entries({ ...mockGroups, ...groupByTypes })
      .map(([key, value]) => {
        return {
          label: key,
          data:
            value?.map((item) => {
              return {
                x: item?.created_at,
                y: item?.face_amount,
              };
            }) ?? [],
          borderColor: Statistics.colors[key],
          fill: true,
        };
      })
      // remove referrals data
      .filter((group) => group.label !== Statistics.statisticTypes[40]);

    return {
      dataset,
    };
  }

  static formatDate(date) {
    const dateMontShort = new Intl.DateTimeFormat("en-US", {
      month: "short",
      // day: "2-digit",
      year: "2-digit",
    });

    return dateMontShort.format(date);
  }
}
