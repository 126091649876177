<template>
  <div class="earning-card">
    <div class="earning-card__info">
      <div class="earning-card__date-time">
        <span class="earning-card__font earning-card__font--base">
          {{ dateTime.date }}
        </span>

        <span class="earning-card__font earning-card__font--secondary">
          {{ dateTime.time }}
        </span>
      </div>

      <div class="earning-card__amount">
        <span class="earning-card__font earning-card__font--base">
          {{ amount }}
        </span>

        <span class="earning-card__font earning-card__font--secondary">
          {{ $t("general.earningCard.amount") }}
        </span>
      </div>

      <div class="earning-card__amount">
        <span class="earning-card__font earning-card__font--base">
          {{ fee }}
        </span>

        <span class="earning-card__font earning-card__font--secondary">
          {{ $t("general.earningCard.fee") }}
        </span>
      </div>

      <div class="earning-card__amount">
        <span class="earning-card__font earning-card__font--base">
          {{ net }}
        </span>

        <span class="earning-card__font earning-card__font--secondary">
          {{ $t("general.earningCard.net") }}
        </span>
      </div>
    </div>

    <div class="earning-card__footer">
      <UiBadge class="earning-card__badge" size="medium" v-if="description">
        <span class="earning-card__font earning-card__font--badge">
          {{ description.message }}

          <CLink v-if="description.user" :to="`/${description.user}`">
            {{ description.name }}
          </CLink>
        </span>
      </UiBadge>

      <span class="earning-card__verification">
        <span class="earning-card__font earning-card__font--verified"
          >{{ $t("general.earningCard.verified") }}
        </span>

        <CIcon
          name="check"
          class="earning-card__icon earning-card__icon--check"
        />
      </span>
    </div>
  </div>
</template>
<script>
import UiBadge from "@/features/ui/common/UiBadge.vue";
import { currencyFormat, formatDateTime } from "@/tools/helpers";
import { mapGetters } from "vuex";
import CLink from "@/features/ui/CLink.vue";
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "EarningCard",
  components: { CIcon, CLink, UiBadge },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings",
    }),
    dateTime() {
      return this.formatDateTime(this.card?.created_at);
    },

    description() {
      const cardType = this.card?.type;
      const answerCode = this.settings?.payment_types?.[cardType];
      const answers = {
        TYPE_SUBSCRIPTION_NEW: {
          message: `Subscription from `,
          user: this.card?.to?.username || "test",
          name: this.card?.to?.name || "name",
        },
        TYPE_SUBSCRIPTION_RENEW: {
          message: "Renew subscription ",
          user: this.card?.items?.sub?.username || "",
          name: this.card?.items?.sub?.name || "",
        },
        TYPE_POST: {
          message: "Unlocked ",
          user: `${this.card?.items?.post?.id}/${this.card?.items?.post?.user?.name}`,
          name: "Post",
        },
        TYPE_MESSAGE: {
          message: "Tip message",
          user: this.card?.items?.sub?.username || "",
          name: this.card?.items?.sub?.name || "",
        },
        TYPE_TIP: {
          message: `Tip from `,
          user: this.card?.to?.username || "",
          name: this.card?.to?.name || "",
        },
        TYPE_WALLET_DEPOSIT: { message: "Added funds from card" },
      };

      return answers[answerCode] || answers.TYPE_SUBSCRIPTION_NEW;
    },

    amount() {
      return this.currencyFormat(this.card?.face_amount || 0);
    },
    fee() {
      return this.currencyFormat(this.card?.face_fee || 0);
    },
    net() {
      return this.currencyFormat(this.card?.face_net || 0);
    },
  },
  methods: {
    currencyFormat,
    formatDateTime,
  },
};
</script>

<style lang="scss" scoped>
.earning-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  height: 100px;
  padding: 16px 15px 18px;
  margin-top: 16px;

  &__image {
    display: inline-block;
    width: 29px;
    height: 17px;
  }

  &__icon {
    &--check {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      margin-left: 3px;
    }
  }

  &__font {
    &--base {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      text-align: right;
      color: $black;
    }

    &--secondary {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 10px;
      text-align: right;
      color: $app-gray-18;
      text-transform: uppercase;
    }

    &--badge {
      font-size: 12px;
      color: $app-gray-19;
    }

    &--name {
      color: $app-blue;
    }

    &--verified {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 12px;
      color: $app-gray-19;
    }
  }

  &__badge {
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__date-time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__footer {
    margin-top: em(6);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__badge {
    height: em(26);
  }
}
</style>
