<template>
  <div>
    <div class="mt-1">
      <div
        class="statistics-spoiler mt-3"
        :class="{ 'statistics-spoiler--open': openSpoiler }"
      >
        <div class="referral-card__header">
          <div class="referral-card__card">
            <div class="referral-card__description">
              <div class="referral-card__info">
                <span class="referral-card__font referral-card__font--name">
                  {{ title }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          v-b-toggle="spoilerId"
          class="statistics-spoiler__button statistics-spoiler__button--arrow"
          :class="{
            'statistics-spoiler__button--arrow--open': openSpoiler,
          }"
        >
          <CIcon
            name="arrow-to-top"
            path="profile"
            class="statistics-spoiler__icon statistics-spoiler__icon--arrow-to-top"
          />
        </button>
      </div>

      <b-collapse :id="spoilerId" v-model="spoilerModel[spoilerKey]">
        <div class="statistics-spoiler-list">
          <div class="chart">
              <LineChart
                :key="spoilerModel[spoilerKey]"
                :chart-id="title"
                :selectedLine="selectedTab.id"
                :data-sets-data="dataSetsData"
              />
          </div>

          <div class="statistics-spoiler-list__list">
            <CRadio
              v-model="selectedTab"
              v-for="(item, index) in list"
              :key="`${index + '-tab'}`"
              :name="item.name"
              :value="item"
              :group-name="`statistic-tab-${Math.random()}`"
              class="tab"
            >
              <template #default="{ isActive, value: item }">
                <div
                  class="statistics-spoiler-list__item"
                  :class="{
                    'statistics-spoiler-list__item--active': isActive,
                    [`statistics-spoiler-list__item--${item.dotTheme}`]:
                      item.dotTheme,
                  }"
                  :key="item.id"
                >
                  <div class="statistics-spoiler-list__left">
                    <span
                      class="statistics-spoiler-list__font statistics-spoiler-list__font--item"
                    >
                      {{ item.name }}
                    </span>
                  </div>

                  <div class="statistics-spoiler-list__right">
                    <span
                      class="statistics-spoiler-list__font statistics-spoiler-list__font--amount"
                    >
                      {{ item.value }}
                    </span>
                  </div>
                </div>

                <div
                  :key="item.id + item.name"
                  class="statistics-spoiler-list__hr"
                  :class="{
                    'statistics-spoiler-list__hr--blue': item.isLast,
                  }"
                />
              </template>
            </CRadio>

            <div class="statistics-spoiler-list__bottom">
              <div class="statistics-spoiler-list__left">
                <span
                  class="statistics-spoiler-list__font statistics-spoiler-list__font--amount statistics-spoiler-list__font--base"
                >
                  {{ $t("general.statisticsSpoiler.total") }}
                </span>
              </div>

              <div class="statistics-spoiler-list__right">
                <span>
                  <span class="statistics-spoiler-list__font--amount">
                    {{ $t("general.statisticsSpoiler.gross") }}
                  </span>

                  <span
                    class="statistics-spoiler-list__font statistics-spoiler-list__font--amount statistics-spoiler-list__font--base"
                  >
                    {{ totalGross }}
                  </span>
                </span>
              </div>

              <div class="statistics-spoiler-list__right">
                <span>
                  <span class="statistics-spoiler-list__font--amount">
                    {{ $t("general.statisticsSpoiler.net") }}
                  </span>

                  <span
                    class="statistics-spoiler-list__font statistics-spoiler-list__font--amount statistics-spoiler-list__font--base"
                  >
                    {{ totalNet }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";
import CRadio from "@/features/ui/CRadio.vue";
import LineChart from "@/features/containers/statements/components/LineChart.vue";
import { Statistics } from "@/services/Stattistics";

export default {
  name: "StatisticsSpoiler",
  components: { CRadio, CIcon, LineChart },
  props: {
    spoilerKey: {
      type: [String, Number],
      default: Math.random(),
    },
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    spoilerId() {
      return `collapse-spoiler-${this.spoilerKey}`;
    },
    openSpoiler() {
      return this.spoilerModel[this.spoilerKey];
    },

    totalGross() {
      return `$${this.aggregatedData.gross}`;
    },

    totalNet() {
      return `$${this.aggregatedData.net}`;
    },
    title() {
      return this.card?.title || "";
    },

    aggregatedData() {
      return Statistics.aggregate(this.card.data || []);
    },

    list() {
      return [
        {
          id: 0,
          name: this.$t("general.statisticsSpoiler.subscriptions"),
          value: this.toCurrencyFormat(this.aggregatedData.subscriptions),
          // value: this.toCurrencyFormat(this.aggregatedData.subscriptions),
          dotTheme: Statistics.colors.subscriptions,
          hr: true,
        },
        {
          id: 3,
          name: this.$t("general.statisticsSpoiler.tips"),
          value: this.toCurrencyFormat(this.aggregatedData.tips),
          dotTheme: Statistics.colors.tips,
          hr: true,
        },
        {
          id: 1,
          name: this.$t("general.statisticsSpoiler.posts"),
          value: this.toCurrencyFormat(this.aggregatedData.posts),
          dotTheme: Statistics.colors.posts,
          hr: true,
        },
        {
          id: 2,
          name: this.$t("general.statisticsSpoiler.messages"),
          value: this.toCurrencyFormat(this.aggregatedData.messages),
          dotTheme: Statistics.colors.messages,
          hr: true,
        },
        // {
        //   id: 4,
        //   name: this.$t("general.statisticsSpoiler.referrals"),
        //   value: this.toCurrencyFormat(this.aggregatedData.referrals),
        //   dotTheme: Statistics.colors.referrals,
        //   hr: true,
        // },
        // {
        //   id: 6,
        //   name: this.$t("general.statisticsSpoiler.streams"),
        //   value: this.toCurrencyFormat(3232323),
        //   dotTheme: "clai",
        //   hr: true,
        //   isLast: true,
        // },
      ];
    },

    dataSetsData() {
      return Statistics.prepareChartData(this.card?.data || []);
    },
  },
  data() {
    return {
      selectedTab: {
        id: 0,
        name: this.$t("general.statisticsSpoiler.subscriptions"),
        value: this.toCurrencyFormat(12.02),
        dotTheme: Statistics.colors.subscriptions,
        hr: true,
      },
      randomKey: Math.random(),
      spoilerModel: {
        [this.spoilerKey]: true,
      },
    };
  },
  methods: {
    toCurrencyFormat(num) {
      return `$${num}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-spoiler {
  $parent: &;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid $app-gray-29;

  &__buttons {
    //margin-top: 12px;
  }

  &__button {
    &:not(:first-of-type) {
      margin-top: 11px;
    }

    &--arrow {
      margin-left: auto;
      margin-top: 0;

      &--open {
        #{$parent}__icon--arrow-to-top {
          transform: rotate(360deg);
          transition: $ease-out-cubic;
        }
      }
    }
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    color: $black;
    flex: 1;
    white-space: nowrap;
  }

  &__icon {
    color: $black;
    &--discount {
      width: 17px;
      height: 17px;
      min-width: 17px;
      min-height: 17px;
      margin-right: 6px;
    }

    &--arrow-to-top {
      transform: rotate(180deg);
      margin-left: auto;
      width: 15px;
      height: 15px;
    }
  }

  &--open {
    border-radius: 6px 6px 0 0;
    border-bottom: 0;
  }
}

.statistics-spoiler-list {
  $parent: &;

  padding: 0 16px 16px 16px;
  border: 1px solid $app-gray-29;
  border-radius: 0 0 6px 6px;
  border-top: 0;

  &__hr {
    border-top: 1px solid #e8e8e8;
    height: 0px;
    //margin-left: -7px;
    //margin-right: -7px;
    margin-top: 7px;
    margin-bottom: 7px;

    &--blue {
      border-top: 2px solid $app-blue;
    }
  }

  &__font {
    &--head {
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 16px;
      color: $app-gray-33;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 16px;
    position: relative;

    &::after {
      content: "";
      width: 4px;
      height: 4px;
      position: absolute;
      left: -9px;
      top: 50%;
      border-radius: 50%;
      background-color: $app-blue;
      transform: translate3d(0, -50%, 0);
      filter: grayscale(100%);
    }

    &--blue {
      &::after {
        background-color: blue;
      }
    }

    &--cherry {
      &::after {
        background-color: crimson;
      }
    }

    &--orange {
      &::after {
        background-color: orange;
      }
    }

    &--turquoise {
      &::after {
        background-color: turquoise;
      }
    }

    &--violet {
      &::after {
        background-color: violet;
      }
    }

    &--active {
      #{$parent}__font {
        color: $app-black;
      }

      &::after {
        filter: grayscale(50%);
      }
    }
  }

  &__font {
    &--item {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 12px;

      color: $app-gray-15;
    }

    &--amount {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 12px;
      text-align: right;
      color: $app-gray-18;
    }

    &--base {
      color: $app-black;
    }
  }
}

.referral-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  height: 164px;
  padding: 16px 15px;
  margin-top: 16px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__image-wrapper {
  }

  &__image {
    width: 33px;
    height: 33px;
    border-radius: 50%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin-left: em(11);

    &--right {
      align-items: flex-end;
      margin-right: em(17);
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__title {
  }

  &__badge {
    margin-top: em(6);
  }

  &__font {
    &--description {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 10px;
      color: $app-gray-18;
    }

    &--name {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      color: $app-black;
    }

    &--toggle {
      font-weight: 400;
      font-size: 12px;
      color: $app-gray-18;
    }
  }

  &__badge {
  }

  &__info {
    display: inline-flex;
    align-items: center;
  }

  &__icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  &__button {
    margin-top: em(16);
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: em(14);
  }

  &__toggle {
    margin-left: em(10);
    font-size: 50%; // change toggle default size
  }

  &__tooltip {
    padding: em(18) em(18) em(20);
  }

  &__button {
    color: $black;
    text-decoration: none;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: em(15);
    }

    &:active {
      transform: none;
      filter: none;
    }
  }
}

.statistics-spoiler-list {
}
</style>
